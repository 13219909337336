
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -40px;
  width: 80px;
  display: block;
}
