@import "https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700;9..40,800&amp;display=swap";

:root {
    --theme-color-light: #ffffff;
    --theme-color-dark: #000000;
    --theme-color1: #EF5B23;
    --theme-color2: #ffbf43;
    --text-color: #444444;
    --headings-color: var(--theme-color-dark);
    --link-color: var(--theme-color-dark);
    --link-hover-color: var(--theme-color-dark);
    --title-font: "DM Sans", sans-serif;
    --text-font: "DM Sans", sans-serif;
    --body-font-size: 16px;
    --body-line-height: 30px;
    --body-font-weight: 400;
    --line-height-heading-h1: 1em;
    --line-height-heading: 1.2em;
    --line-height-heading-small: 1.4em;
    --h1-font-size: 84px;
    --h2-font-size: 46px;
    --h3-font-size: 34px;
    --h4-font-size: 24px;
    --h5-font-size: 20px;
    --h6-font-size: 18px;
    --h1-font-weight: 700;
    --h2-font-weight: 700;
    --h3-font-weight: 700;
    --h4-font-weight: 400;
    --h5-font-weight: 400;
    --h6-font-weight: 400;
    --sec-title-subtitle-color: var(--theme-color1);
    --sec-title-subtitle-font-size: 16px;
    --sec-title-subtitle-font-family: var(--title-font);
    --sec-title-subtitle-font-weight: var(--h2-font-weight);
    --sec-title-subtitle-line-height: 24px;
    --sec-title-color: var(--theme-color-dark);
    --sec-title-font-size: 50px;
    --sec-title-font-family: var(--title-font);
    --sec-title-font-weight: var(--h2-font-weight);
    --theme-light-background: #f8f6f1;
    --theme-light-background-text-color: var(--headings-color);
    --theme-black: #131313;
    --container-width: 1200px;
    --small-container-width: 1050px;
    --large-container-width: 1800px;
    --container-pt: 120px;
    --body-font-weight-bold: 700;
    --container-pb: 120px
}

::-moz-selection {
    background: var(--theme-color-dark);
    color: #fff;
    text-shadow: none
}

::selection {
    background: var(--theme-color-dark);
    color: #fff;
    text-shadow: none
}

:active,
:focus {
    outline: none !important
}

::-webkit-input-placeholder {
    color: #7c858c
}

::-moz-input-placeholder {
    color: #7c858c
}

::-ms-input-placeholder {
    color: #7c858c
}

body {
    background-color: #fff;
    background-attachment: fixed;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    counter-reset: my-sec-counter;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    color: var(--text-color);
    font-size: var(--body-font-size);
    font-family: var(--text-font) !important;
    font-weight: var(--body-font-weight);
    line-height: var(--body-line-height)
}

p,
.text {
    color: var(--text-color);
    font-size: var(--body-font-size);
    font-family: var(--text-font);
    font-weight: var(--body-font-weight);
    line-height: var(--body-line-height);
    letter-spacing: -.02em
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--headings-color);
    font-family: var(--title-font);
    position: relative;
    line-height: var(--line-height-heading-)
}

h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small {
    font-weight: 400;
    line-height: 1;
    color: var(--headings-color)
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit;
    font-weight: inherit
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 1rem
}

h1 {
    font-size: var(--h1-font-size);
    font-weight: var(--h1-font-weight);
    line-height: var(--line-height-heading-h1)
}

h2 {
    font-size: var(--h2-font-size) !important;
    font-weight: var(--h2-font-weight) !important;
    line-height: var(--line-height-heading)
}

h3 {
    font-size: var(--h3-font-size) !important;
    font-weight: var(--h3-font-weight) !important;
    line-height: var(--line-height-heading) !important
}

h4 {
    font-size: var(--h4-font-size);
    font-weight: var(--h4-font-weight);
    line-height: var(--line-height-heading)
}

h5 {
    font-size: var(--h5-font-size);
    font-weight: var(--h5-font-weight);
    line-height: var(--line-height-heading)
}

h6 {
    font-size: var(--h6-font-size) !important;
    font-weight: var(--h6-font-weight);
    line-height: var(--line-height-heading-small)
}

table p {
    margin-bottom: 0
}

p {
    margin-bottom: 20px
}

p a:not(.button):not(.btn):hover,
p a:not(.button):not(.btn):focus {
    text-decoration: underline
}

a {
    color: var(--link-color) ;
    text-decoration: none !important;
    font-weight: var(--body-font-weight);
    cursor: pointer;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

a:hover,
a:focus {
    color: inherit !important;
    text-decoration: none;
    outline: none
}

a b,
a strong {
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

a img {
    border: none
}

pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset {
    margin-bottom: 10px;
}

ol,
ul {
    list-style-position: inside;
    margin: 0;
    padding: 0 !important
}

b,
strong {
    color: #333;
    font-weight: var(--body-font-weight-bold)
}

iframe {
    border: none !important
}

.container .container {
    width: 100%
}

.container .container,
.container .container-fluid,
.container-fluid .container,
.container-fluid .container-fluid {
    padding-left: 0;
    padding-right: 0
}

section>.container,
section>.container-fluid {
    padding-top: var(--container-pt);
    padding-bottom: var(--container-pt)
}

@media(min-width:1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: var(--container-width)
    }
}

.form-control,
.input-text {
    height: calc(2.25rem + 27px);
    padding: 14px 30px;
    outline: 0;
    background-color: #f4f5f8;
    border: 1px solid #f4f5f8;
    color: #686a6f;
    font-size: .9rem;
    width: 100%
}

.form-control::-webkit-input-placeholder,
.input-text::-webkit-input-placeholder {
    color: #686a6f;
    opacity: 1
}

.form-control::-moz-placeholder,
.input-text::-moz-placeholder {
    color: #686a6f;
    opacity: 1
}

.form-control:-ms-input-placeholder,
.input-text:-ms-input-placeholder {
    color: #686a6f;
    opacity: 1
}

.form-control::-ms-input-placeholder,
.input-text::-ms-input-placeholder {
    color: #686a6f;
    opacity: 1
}

.form-control::placeholder,
.input-text::placeholder {
    color: #686a6f;
    opacity: 1
}

.form-control:-ms-input-placeholder,
.input-text:-ms-input-placeholder {
    color: #686a6f
}

.form-control::-ms-input-placeholder,
.input-text::-ms-input-placeholder {
    color: #686a6f
}

textarea.form-control {
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px
}

* {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    font-size: 100%
}

textarea {
    overflow: hidden;
    resize: none
}

button {
    outline: none !important;
    cursor: pointer
}

img {
    display: inline-block;
    max-width: 100%;
    height: auto
}

ul,
li {
    list-style: none;
    padding: 0;
    margin: 0
}

.title a {
    color: inherit
}

.color1 {
    color: var(--theme-color1)
}

.color2 {
    color: var(--theme-color2)
}

.color3 {
    color: var(--theme-color3)
}

.page-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
    overflow: hidden;
    z-index: 99;
    background-color: #fff
}

.large-container {
    position: static;
    max-width: var(--large-container-width);
    padding: 0 15px;
    margin: 0 auto;
    width: 100%
}

.auto-container {
    position: static;
    max-width: var(--container-width);
    padding: 0 15px;
    margin: 0 auto;
    width: 100%
}

.small-container {
    position: static;
    max-width: var(--small-container-width);
    padding: 0 15px;
    margin: 0 auto;
    width: 100%
}

.pull-right {
    float: right
}

.pull-left {
    float: left
}

.dropdown-toggle::after {
    display: none
}

.scroll-to-top {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 40px;
    font-size: 16px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    background-color: var(--theme-color1);
    z-index: 100;
    display: none;
    border-radius: 50%;
    margin: 0 auto;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease
}

.scroll-to-top:hover {
    background: var(--theme-color-dark);
    color: var(--theme-color-light)
}

.theme-color2 {
    background-color: var(--theme-color2);
}

.top-left ul, 
.top-right ul {
    margin-bottom: 0;
}

.mr_5 {
    margin-right: 5px;
}

.green-bg {
    background-color: rgb(0, 205, 0) !important;
}